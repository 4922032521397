import React from 'react';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { displayCentsAsDollars } from '../../utils';
import NoImage from '../../assets/no-image.png';
import { FORMATS_DATES } from '../../constants';
import { Date } from '../date';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '60px',
    height: '60px',
    minWidth: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridArea: 'image',
      width: '50px',
      minWidth: '50px',
      height: '50px',
      padding: 0,
      marginLeft: 0,
    },
  },
  name: {
    fontSize: '15px',
    lineHeight: '18px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  title: {
    fontSize: '15px',
    lineHeight: '16px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  price: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'var(--dark-gray)',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  qty: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'var(--middle-gray)',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  totalDescription: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'var(--middle-gray)',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  amountPrice: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  createdValue: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '18px',
    color: 'var(--middle-gray)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
}));

const Item = ({ price, qty, imageUrl, name, index }) => {
  const classes = useStyles();
  return (
    <>
      {index === 0 && <Divider />}
      <Box display="flex" alignItems="center" bgcolor="white.main" height="94px" padding={4}>
        <div className={classes.image}>
          <img src={imageUrl || NoImage} alt={name} className={classes.image} />
        </div>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          justifyContent={{ md: 'space-between' }}
          minWidth="0"
          width="100%"
        >
          <span className={classes.name}>{name}</span>
          <Box display="flex" justifyContent="space-between" width={{ md: '200px' }}>
            <span className={classes.qty}>{`${qty} x ${displayCentsAsDollars(price)}`}</span>
            <span className={classes.price}>{`${displayCentsAsDollars(qty * price)}`}</span>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export const FinancesItem = ({ type, paymentTotals, createdAt }) => {
  const classes = useStyles();

  const getTitle = () => {
    switch (type) {
      case 'income':
        return 'Payment';
      case 'refund':
        return 'Refund';
      default:
        return 'Balance';
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" pt="4px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          margin={6}
          marginBottom={6}
          width={{ xs: '100%', md: '250px' }}
        >
          <h3 className={classes.title}>{getTitle()}</h3>
          <span className={classes.createdValue}>
            {createdAt && <Date date={createdAt} format={FORMATS_DATES.fullWithTime} />}
          </span>
        </Box>
      </Box>
      {paymentTotals?.paymentItems.map((item, index) => (
        <Item
          key={item.item.uid}
          price={item.price}
          qty={item.qty}
          imageUrl={item.item?.primaryImage?.url}
          name={item.item?.name}
          index={index}
        />
      ))}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ md: 'flex-end' }}
        spacing={2}
        px="16px"
        fontSize="14px"
      >
        <Box display="flex" justifyContent="space-between" width={{ md: '200px' }} marginTop={3}>
          <span className={classes.totalDescription}>Subtotal</span>
          <span className={classes.price}>{displayCentsAsDollars(paymentTotals?.subtotal)}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" width={{ md: '200px' }}>
          <span className={classes.totalDescription}>Shipping fee</span>
          <span className={classes.price}>
            {displayCentsAsDollars(paymentTotals?.totalShipping)}
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" width={{ md: '200px' }}>
          <span className={classes.totalDescription}>Sales tax</span>
          <span className={classes.price}>{displayCentsAsDollars(paymentTotals?.totalTax)}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" width={{ md: '200px' }}>
          <span className={classes.totalDescription}>Discount</span>
          <span className={classes.price}>
            -{displayCentsAsDollars(paymentTotals?.totalDiscount)}
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" width={{ md: '200px' }}>
          <span className={classes.totalDescription}>Amount</span>
          <span className={classes.amountPrice}>{displayCentsAsDollars(paymentTotals?.total)}</span>
        </Box>
      </Box>
      <Divider style={{ width: '100%', borderColor: '#d1d5db', margin: '20px 0' }} />
    </>
  );
};
