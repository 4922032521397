import React, { useMemo } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { displayCentsAsDollars, getOrderSubTotal, setPlaceholderImageOnError } from '../../utils';

const useStyles = makeStyles((theme) => ({
  cartRow: {
    borderBottom: '1px solid var(--light-gray)',
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: 'unset',
      gridTemplateColumns: '80px 1fr 80px 80px 90px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '60px 1fr 1fr 80px',
      gridTemplateAreas: `
                    'image name name name'
                     'image quantity price amount'
                `,
      gridGap: '5px 10px',
      padding: '8px 0px',
    },
    gridGap: '20px',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
  },
  returnedItemsRow: {
    paddingBottom: '80px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '110px',
    },
  },
  subTotalRow: {
    borderBottom: 'none',
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: 'unset',
      gridTemplateColumns: '1fr 90px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '60px 1fr 1fr 80px',
      gridTemplateAreas: `
                     'image quantity price amount'
                `,
    },
  },
  subTotalLabel: {
    fontSize: '13px',
    lineHeight: '21px',
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.gray.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      gridArea: 'price',
      textAlign: 'right !important',
      paddingRight: '15px',
    },
  },
  subTotalValue: {
    fontSize: '15px',
    lineHeight: '28px',
    textAlign: 'center',
    fontWeight: 700,
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      gridArea: 'amount',
      textAlign: 'right !important',
      paddingRight: '15px',
    },
  },
  cartHeader: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 80px 80px 90px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '60px 1fr 1fr 80px',
      gridColumnGap: '10px',
      paddingTop: 0,
      paddingBottom: 0,
    },
    gridGap: '0',
    paddingBottom: '7px',
    gridColumnGap: '20px',
    '& > span': {
      textAlign: 'center',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '22px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
    '& > span:first-child': {
      textAlign: 'left',
      paddingLeft: '100px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
      },
    },
  },
  image: {
    width: '60px',
    height: '60px',
    marginLeft: theme.spacing(2),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 5px',
    [theme.breakpoints.down('sm')]: {
      gridArea: 'image',
      marginLeft: theme.spacing(2),
      width: '50px',
      height: '50px',
      padding: 0,
    },
  },
  name: {
    fontSize: '15px',
    lineHeight: '18px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  attention: {
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.red.secondary,
  },
  names: {
    [theme.breakpoints.down('sm')]: {
      gridArea: 'name',
    },
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      gridArea: 'amount',
      fontSize: '12px',
      textAlign: 'right',
      paddingRight: '15px',
    },
  },
  sku: {
    fontSize: '15px',
    fontWeight: '500',
    color: 'var(--dark-gray)',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      gridArea: 'sku',
      fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  price: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'var(--dark-gray)',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gridArea: 'price',
      fontSize: '12px',
      textAlign: 'right',
      paddingRight: '15px',
    },
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'var(--dark-gray)',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      gridArea: 'quantity',
      fontSize: '12px',
    },
  },
  headerPrice: {
    textAlign: 'center!important',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right !important',
      paddingRight: '15px',
    },
  },
  headerQty: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left !important',
    },
  },
  headerAmount: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right !important',
      paddingRight: '15px',
    },
  },
  returnedDetails: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'grid',
    gridGap: '0 20px',
    width: 'calc(100% - 100px)',
    padding: '15px 0 20px',
    gridTemplateColumns: '1fr 80px 80px 90px 90px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '1px',
      backgroundColor: 'var(--light-gray)',
      top: 0,
      left: 0,
    },
  },
  returnedDetailsLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'var(--dark-gray)',
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '25px',
    },
  },
  returnedDetailsIcon: {
    marginRight: '5px',
  },
}));

export const OrderItems = ({ orderItems }) => {
  const classes = useStyles();
  const subTotal = useMemo(() => getOrderSubTotal({ orderItems }), [orderItems]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <div className={`${classes.cartRow} ${classes.cartHeader}`}>
        <span>
          <Hidden smDown>Items</Hidden>
        </span>

        <Hidden smDown>
          <span>SKU</span>
        </Hidden>

        <span className={classes.headerQty}>Qty:</span>
        <span className={classes.headerPrice}>Unit Price</span>
        <span className={classes.headerAmount}>Amount</span>
      </div>

      {orderItems?.length ? (
        orderItems.map((item) => (
          <div key={item.uid} className={clsx(classes.cartRow)}>
            <div className={classes.image}>
              <img src={item.image} alt={item.name} onError={setPlaceholderImageOnError} />
            </div>
            <div className={classes.names}>
              <h3 className={classes.name}>{item.name}</h3>
              <Hidden mdUp>
                <div className={classes.sku}>{item?.sku}</div>
              </Hidden>
            </div>

            <Hidden smDown>
              <div className={classes.sku}>{item?.sku}</div>
            </Hidden>
            <div className={classes.quantity}>{item?.qty}</div>

            <span className={classes.price}>{displayCentsAsDollars(item.price)}</span>

            <div className={classes.totalPrice}>
              {displayCentsAsDollars(item?.qty * item?.price)}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
      <div className={clsx(classes.cartRow, classes.subTotalRow)}>
        <div />
        <div className={classes.subTotalLabel}>Subtotal:</div>
        <div className={classes.subTotalValue}>{subTotal}</div>
      </div>
    </Grid>
  );
};
