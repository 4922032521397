import React from 'react';
import { EmptyTab } from '../empty-tab';

export const ProductOverviewTab = ({ description, overviews }) => {
  return (
    <div className="text-dark-gray container mx-auto">
      {!overviews?.length && !description.length ? (
        <EmptyTab />
      ) : (
        <div className="grid gap-4">
          <div className="col-span-12">
            <h3 className="mb-2 pt-4 text-lg font-bold text-black md:pt-2">Description</h3>
            <p className="text-black" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          {overviews.map((item) => (
            <div key={item.uid} className="col-span-12">
              <h3
                className="mb-2 text-lg font-bold text-black"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <p className="text-black" dangerouslySetInnerHTML={{ __html: item.body }} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
