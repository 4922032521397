import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useMediaQuery } from '@material-ui/core';
import { LeftArrowIcon } from '../icons/Icons';
import { ProductsItem } from '../products-item';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'var(--dark-gray)',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    margin: theme.spacing(0, 0, 2, 0),
  },
  carousel: {
    margin: theme.spacing(0, -2),
    position: 'relative',
    padding: '0',
  },
  icon: {
    width: '24px',
    height: '24px',
    color: 'var(--white)',
  },
  arrowButton: {
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, .1)',
    padding: '6px',
    border: 'none',
    color: 'var(--light-gray)',
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '&:hover': {
      opacity: 0.5,
    },
  },
  productItem: {
    margin: theme.spacing(0, 2),
  },
  back: {
    left: '15px',
  },
  next: {
    right: '15px',
    transform: 'translateY(-50%) rotateZ(180deg)',
  },
  slider: {
    padding: theme.spacing(3, 0),
    height: '245px',
    [theme.breakpoints.up('sm')]: {
      height: '385px',
    },
  },
}));

const selectSlides = (isLargeResolution, isMediumResolution) => {
  if (isLargeResolution) {
    return {
      visibleSlides: 4,
    };
  }
  if (isMediumResolution) {
    return {
      visibleSlides: 3,
    };
  }
  return {
    visibleSlides: 2,
  };
};

export const ProductsWidget = ({ title, items, ...rest }) => {
  const classes = useStyles();

  const isLargeResolution = useMediaQuery('@media(min-width: 1010px)');
  const isMediumResolution = useMediaQuery('@media(min-width: 760px)');

  return (
    <div {...rest}>
      <h2 className={classes.title}>{title}</h2>
      <CarouselProvider
        className={classes.carousel}
        naturalSlideWidth={280}
        naturalSlideHeight={360}
        {...selectSlides(isLargeResolution, isMediumResolution)}
        step={1}
        totalSlides={items.length}
      >
        <Slider className={classes.slider} tabIndex={-1}>
          {items.map((item, index) => (
            <Slide key={item.uid} index={index}>
              <ProductsItem className={classes.productItem} data={item} />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={`${classes.arrowButton} ${classes.back}`}>
          <LeftArrowIcon className={classes.icon} />
        </ButtonBack>
        <ButtonNext className={`${classes.arrowButton} ${classes.next}`}>
          <LeftArrowIcon className={classes.icon} />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};
