import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const SEARCH_PRODUCTS_QUERIES = gql`
  query searchProductsQueries($query: String!, $pagination: PaginationInput, $filter: SearchFilterInput) {
    itemsSearch(query: $query, pagination: $pagination, filter: $filter) {
      nodes {
        uid
        slug
        name
        description
        brand {
          name
          slug
          uid
        }
        categories {
          name
          slug
          uid
        }
        primaryImage {
          url
          mediumUrl
          thumbUrl
        }
        prices {
          currencyCode
          currencySign
          type
          value
        }
        availability {
          availableQty
          availabilityControl
          outOfStockThreshold
          lowInStockThreshold
        }
      }
      ${FRAGMENTS_QUERIES.pagination}
    }
  }
`;
