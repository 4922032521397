import React, { useEffect, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Checkbox, Link, Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import {
  getFormattedOrderItems,
  getItemsIds,
  getLocalStorageBasket,
  getLocalStoreFrontSlug,
  getCheckoutPageForGoogleAnalytics,
} from '../../utils';
import {
  useCreateAddress,
  useCreateOrder,
  useCreateCheckout,
  useErrors,
  useAvailabilityItems,
  useComparingItemsByAvailability,
  useCreateOrderAccount,
  useShoppingCartSummary,
} from '../../hooks';
import { Context } from '../../store';
import { storageService } from '../../services';
import { ACTIONS_TYPES, LOCAL_STORAGE_KEYS, OUTER_LINKS } from '../../constants';
import { ShippingForm } from '../shipping-form';
import { ShoppingCartTotals } from '../shopping-cart-totals';
import { Spinner } from '../spinner';
import { CouponCodeForm } from '../coupon-code-form';
import { useVerifyCouponCode } from '../../hooks/use-verify-coupon-code';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles((theme) => ({
  checkboxText: {
    color: theme.palette.gray.main,
    fontSize: '14px',
  },
  privacyLink: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& > div.MuiCircularProgress-root': {
      width: '22px !important',
      height: '22px !important',
    },
  },
  divider: {
    marginTop: '5px',
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    wordBreak: 'break-word',
  },
}));

export const ShoppingCartShipping = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { errorsHandler } = useErrors();
  const { createOrderAccount } = useCreateOrderAccount();
  const { createAddress } = useCreateAddress();
  const { createOrder } = useCreateOrder();
  const { createCheckout } = useCreateCheckout();
  const { getItemsAvailability } = useAvailabilityItems();
  const { comparingItemsByAvailability } = useComparingItemsByAvailability();
  const formRef = useRef();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const [isErrorValidation, setErrorValidation] = useState(true);
  const { runVerifyCouponCodeQuery, couponCodeUid } = useVerifyCouponCode();

  const { summaryData, loading: summaryIsLoading } = useShoppingCartSummary(
    state?.cart,
    couponCodeUid,
  );

  const [isPrivacyPolicyAccepted, setPrivacyPolicyAcceptStatus] = useState(false);
  const [isTermsOfServiceAccepted, setTermsOfServiceAcceptStatus] = useState(false);

  const handleSubmit = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }

    setTimeout(() => window.scrollTo({ top: 100, left: 100, behavior: 'smooth' }), 0);
  };

  const proceedPayment = async (values) => {
    try {
      setIsLoading(true);

      const basket = await getLocalStorageBasket();

      const shoppingCartItemsIds = await getItemsIds(basket, 'uid');
      const availabilityItems = await getItemsAvailability(shoppingCartItemsIds);
      const { isError, newCart } = await comparingItemsByAvailability(availabilityItems, basket);
      const orderItems = getFormattedOrderItems(basket);

      if (isError) {
        storageService.setItem(LOCAL_STORAGE_KEYS.isError, true);
        dispatch({ type: ACTIONS_TYPES.UPDATE_LOCAL_CART, payload: newCart });
        return push(`/${storeFrontSlug}/cart`);
      }

      const stripe = await stripePromise;

      const createAccountData = await createOrderAccount({
        formalName: `${values?.firstName?.trim?.()} ${values?.lastName?.trim?.()}`,
        email: values.email,
        phone: values.phone,
        orderNotificationsType: values.orderNotificationsType,
      });

      const createAddressData = await createAddress({
        accountUid: createAccountData?.uid ?? '',
        values,
      });

      const createOrderData = await createOrder({
        buyerUid: createAccountData.uid,
        orderedItems: orderItems,
        shippingAddressUid: createAddressData.uid,
        couponCodeUid,
      });

      const createCheckoutData = await createCheckout({
        orderUid: createOrderData.uid,
        backLinks: {
          successBackUrl: `${window.origin}/${storeFrontSlug}/order-successful`,
          cancelBackUrl: `${window.origin}/${storeFrontSlug}/order-canceled`,
        },
      });

      await stripe.redirectToCheckout({
        sessionId: createCheckoutData?.remoteSession?.id,
      });

      setIsLoading(false);
    } catch (error) {
      errorsHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state.cart && !isLoading) {
      getCheckoutPageForGoogleAnalytics(window, !!state?.account);
    }
  }, [isLoading]);

  const onApplyCouponCode = (code) => {
    runVerifyCouponCodeQuery(code);
  };

  return (
    <Grid container spacing={4}>
      {isLoading && <Spinner className={classes.spinner} />}

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <ShippingForm
          formRef={formRef}
          account={state?.account}
          onSubmit={proceedPayment}
          checkError={setErrorValidation}
        />
        <Divider className={classes.divider} />
        <CouponCodeForm onApplyCouponCode={onApplyCouponCode} />

        <Grid container alignItems="center">
          <Checkbox
            checked={isPrivacyPolicyAccepted}
            onChange={() => setPrivacyPolicyAcceptStatus((prev) => !prev)}
            name="privacyPolicy"
            className={classes.checkbox}
            color="primary"
          />
          <Typography className={classes.checkboxText}>
            I agree to{' '}
            <Link
              className={classes.privacyLink}
              target="__blank"
              underline="always"
              href={OUTER_LINKS.privacy_policy_url}
            >
              Salonhq Privacy Policy
            </Link>
          </Typography>
        </Grid>

        <div className={classes.container}>
          <Checkbox
            checked={isTermsOfServiceAccepted}
            onChange={() => setTermsOfServiceAcceptStatus((prev) => !prev)}
            name="termsOfService"
            className={classes.checkbox}
            color="primary"
          />
          <Typography className={classes.checkboxText} style={{ marginTop: '10px' }}>
            I agree to{' '}
            <Link
              className={classes.privacyLink}
              target="__blank"
              underline="always"
              href={OUTER_LINKS.term_of_service_url}
            >
              SalonHQ Messaging Terms of Service
            </Link>
          </Typography>
        </div>
      </Grid>

      {/* idButton prop need to Google Analytics, task CEP-2317 and CEP-2393 */}
      {state?.cart?.length && (
        <Grid align="right" item xs={12} sm={12} md={6} lg={6}>
          <ShoppingCartTotals
            summaryData={summaryData}
            summaryIsLoading={summaryIsLoading}
            onClick={handleSubmit}
            isLoading={isLoading}
            areErrorsPresent={!isErrorValidation}
            isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
            isTermsOfServiceAccepted={isTermsOfServiceAccepted}
            idButton="proceed-to-payment"
          />
        </Grid>
      )}
    </Grid>
  );
};
