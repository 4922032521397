import React, { useMemo } from 'react';
import { NavLink, Switch, Route, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Breadcrumbs } from '../../components';
import { getLocalStoreFrontSlug } from '../../utils';
import { ROUTING_CONFIG } from '../../constants';
import { AccountSettings } from '../account-settings';
import { OrdersHistoryListPage } from '../order-history-page';
import { RecommendationsPage } from '../recommendations';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      marginTop: theme.spacing(3),
    },
  },
  linksList: {
    position: 'relative',
    borderRight: '1px solid var(--light-gray)',
    paddingBottom: '50px !important',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      borderBottom: '1px solid var(--light-gray)',
      borderRight: 'none',
      paddingBottom: '10px !important',
      flexWrap: 'nowrap',
      overflow: 'auto',
    },
  },
  linkItem: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      marginLeft: '20px',
      marginRight: '20px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(4),
    },
  },
  link: {
    fontWeight: 600,
    transition: 'all 0.2s',
    color: 'var(--gray)',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  pageTitle: {
    textAlign: 'center',
    color: 'var(--dark-gray)',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(4, 0, 4, 4),
    marginLeft: theme.spacing(32),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const getNavigationLinks = (slug) => [
  {
    title: 'Account Details',
    name: 'Account Settings',
    to: ROUTING_CONFIG.accountSettings.replace(':storeFrontSlug', slug),
  },
  {
    title: 'Order History',
    name: 'Order History',
    to: ROUTING_CONFIG.accountOrderHistory.replace(':storeFrontSlug', slug),
  },
  {
    title: 'Recommendations',
    name: 'Recommendations',
    to: ROUTING_CONFIG.accountRecommendations.replace(':storeFrontSlug', slug),
  },
];

export const AccountDetailsPage = () => {
  const classes = useStyles();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const { pathname } = useLocation();
  const title = useMemo(() => {
    const foundRoute = getNavigationLinks(storeFrontSlug).find(({ to }) => to === pathname);

    return foundRoute?.title ?? '';
  }, [pathname]);

  return (
    <>
      <Breadcrumbs catalogName={title} />

      <h1 className={classes.pageTitle}>{title}</h1>

      <Grid className={classes.container} container>
        <Grid container item md={2} xs={12} direction="column" className={classes.linksList}>
          {getNavigationLinks(storeFrontSlug).map((item) => (
            <Grid item className={classes.linkItem} key={item.to}>
              <NavLink
                exact
                to={item.to}
                className={`${classes.link}`}
                activeClassName={classes.activeLink}
              >
                {item.name}
              </NavLink>
            </Grid>
          ))}
        </Grid>

        <Grid item md={10} xs={12}>
          <Switch>
            <Route exact path={ROUTING_CONFIG.accountSettings}>
              <AccountSettings />
            </Route>

            <Route exact path={ROUTING_CONFIG.accountOrderHistory}>
              <OrdersHistoryListPage />
            </Route>

            <Route exact path={ROUTING_CONFIG.accountRecommendations}>
              <RecommendationsPage />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </>
  );
};
