import React from 'react';
import { setPlaceholderImageOnError } from '../../utils';

export const ResponseStateLayout = ({ image, title, subTitle }) => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <img className="max-w-full" src={image} alt={title} onError={setPlaceholderImageOnError} />
      {title && (
        <h5 className="text-dark-gray mt-4 text-base font-bold leading-7 text-black">{title}</h5>
      )}
      {subTitle && <p className="mt-2 text-sm leading-6 text-gray-500">{subTitle}</p>}
    </div>
  );
};
