import React from 'react';
import { setPlaceholderImageOnError } from '../../utils';

export const OrderStatusLayout = ({
  image,
  imageAlt,
  title,
  subTitle,
  buttonText,
  onClickButton,
}) => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center text-center">
      <img src={image} alt={imageAlt} onError={setPlaceholderImageOnError} />
      <h1 className="text-dark-gray m-0 text-lg font-bold leading-7 text-black">{title}</h1>
      <p className="text-gray mt-2 text-sm leading-5">{subTitle}</p>
      <button
        className="mb-8 mt-8 h-9 w-full max-w-[250px] rounded-[18px] bg-primary py-1 text-xs tracking-wider text-white shadow-[0_2px_20px_rgba(27,27,29,0.15)] hover:bg-white hover:text-primary hover:shadow-[0_2px_20px_rgba(27,27,29,0.15)]"
        onClick={onClickButton}
      >
        {buttonText}
      </button>
    </div>
  );
};
