import React from 'react';
import clsx from 'clsx';
import {
  displayCentsAsDollars,
  getAvailableQuantity,
  getAvailabilityControl,
  getItemsLabel,
  setPlaceholderImageOnError,
} from '../../utils';
import { ProductCartAmount } from '../product-cart-amount';

export const ShoppingCartItems = ({
  cartItems,
  incrementAmount,
  decrementAmount,
  removeFromCart,
  totalAmount,
  totalSum,
}) => (
  <div className="w-full">
    <div className="border-light-gray grid grid-cols-[5rem_4rem_1fr_3rem] items-center gap-5 border-b p-2 md:grid-cols-[80px_1fr_80px_80px_80px]">
      <span />
      <span className="col-start-2 text-center text-2xs uppercase md:col-start-3">Price</span>
      <span className="text-center text-2xs uppercase">Qty</span>
      <span className="text-center text-2xs uppercase">Sum</span>
    </div>
    {cartItems?.length ? (
      cartItems.map((item) => (
        <div
          key={item.uid}
          className="border-light-gray relative grid grid-cols-[5rem_4rem_1fr_3rem] items-center gap-5 border-b py-3 md:grid-cols-[80px_1fr_80px_80px_80px]"
        >
          <div className="row-span-2 flex h-20 w-20 items-center justify-center md:row-span-1">
            <img
              src={item.image}
              alt={item.name}
              onError={setPlaceholderImageOnError}
              className="object-contain"
            />
          </div>
          <div className="col-span-4 col-start-2 pr-7 md:col-span-1">
            <h3 className="text-dark-gray mb-1 text-sm font-bold uppercase text-black">
              {item.name}
            </h3>
            <h5 className="text-sm font-normal text-black">{item.category.name}</h5>
          </div>
          <span className="text-dark-gray col-start-2 text-center text-sm font-bold text-black md:col-start-3">
            {displayCentsAsDollars(item.price)}
          </span>
          <div className="flex justify-center">
            <ProductCartAmount
              small
              value={item.amount}
              minValue={1}
              increment={() => incrementAmount(item.uid)}
              decrement={() => decrementAmount(item.uid)}
              maxQuantity={getAvailableQuantity(item)}
              availabilityControl={getAvailabilityControl(item)}
            />
          </div>
          <div className="text-center text-sm font-bold text-primary">
            {displayCentsAsDollars(item.amount * item.price)}
          </div>
          <button
            className={clsx(
              'absolute right-0 top-2 p-0 hover:bg-none hover:opacity-50',
              'remove-from-cart',
            )}
            onClick={() => removeFromCart(item.uid)}
          >
            <svg className="remove-from-cart-icon h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19 6.41L17.59 5l-5.59 5.59L6.41 5 5 6.41l5.59 5.59L5 17.59 6.41 19l5.59-5.59L17.59 19 19 17.59l-5.59-5.59z" />
            </svg>
          </button>
        </div>
      ))
    ) : (
      <></>
    )}

    <div className="mt-6 hidden justify-end lg:flex">
      <div className="flex items-center justify-center gap-x-4 text-sm font-semibold">
        <span>{`Subtotal (${totalAmount} ${getItemsLabel(totalAmount)}):`}</span>
        <span className="block text-xl font-bold text-primary">
          {displayCentsAsDollars(totalSum)}
        </span>
      </div>
    </div>
  </div>
);
