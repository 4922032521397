import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { PlusIcon, MinusIcon } from '../icons/Icons';
import { EmptyTab } from '../empty-tab';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    color: 'var(--dark-gray)',
  },
  content: {
    flexDirection: 'row-reverse', // Align content to the right
    alignItems: 'center', // Center items vertically
    width: '100%', // Set width to 100%
  },
  labelText: {
    flexGrow: 1,
    textAlign: 'left', // Align text to the left
  },
  hr: {
    width: '100%',
    borderTop: '1px solid var(--dark-gray)', // Customize border color and style
  },
  '@global': {
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label':
      {
        backgroundColor: 'transparent',
      },
  },
}));

export const ProductFaqTab = ({ questions }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {!questions?.length ? (
        <EmptyTab />
      ) : (
        <TreeView
          className={classes.container}
          defaultCollapseIcon={<MinusIcon />}
          defaultExpandIcon={<PlusIcon />}
        >
          {questions.map((item, index) => (
            <>
              <hr className={classes.hr} />
              <TreeItem
                nodeId={item.uid}
                classes={{ content: classes.content }} // Apply custom content style
                label={
                  <div className={classes.content}>
                    <h3 className="my-6 grow text-left text-lg font-bold">{item.question}</h3>
                  </div>
                }
                key={item.uid}
              >
                <Typography className="pb-2">{item.answer}</Typography>
              </TreeItem>
              {index === questions.length - 1 && <hr className={classes.hr} />}
            </>
          ))}
        </TreeView>
      )}
    </Grid>
  );
};
